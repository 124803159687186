<template>
  <div @click="onOpenPopup">
    <slot :select-city-name="selectCityName">
      <div v-bind="$attrs" class="flex items-center rounded-3xl bg-[#F3F3F3]">
        <div
          class="h-full flex items-center rounded-3xl bg-black px-3 text-xs text-white leading-9"
        >
          <span font="800" max-w="20" class="truncate">{{ selectCityName }}</span>
          <div
            class="ml-1 mt-[1px] h-0 w-0 border-0 border-l-5 border-r-5 border-t-6 border-transparent border-t-white border-solid"
          ></div>
        </div>
        <div class="flex-1 px-2.5 text-sm text-[#B3B3B3] font-300 leading-9">
          {{ t('placeholder') }}
        </div>
      </div>
    </slot>
  </div>

  <van-popup
    v-model:show="showPopup"
    position="bottom"
    class="az-search__popup"
    close-icon-position="top-left"

    closeable safe-area-inset-bottom close-on-popstate
  >
    <div class="h-full flex flex-col">
      <div class="py-1.5 pl-10">
        <van-search
          v-model="searchInput"
          left-icon=""
          shape="round"
          :placeholder="t('placeholder')"
          class="az-search"
        />
      </div>

      <div class="flex-1 overflow-y-auto">
        <van-divider style="margin-top: 0px" />
        <div v-if="isSearching">
          <template v-if="searchCityList.length">
            <lazy-component v-for="item in searchCityList" :key="item.destinationId">
              <div class="flex px-4 py-1.25" @click="onConfirm(item)">
                <div h="11" w="11" flex="~ items-center justify-center" rounded="xl" bg="#f6f6f6">
                  <img class="h-6 w-6" src="~assets/icons/icon_location.png" />
                </div>
                <div class="ml-2.5 flex flex-col justify-between py-0.5">
                  <div
                    class="text-base text-[#121212] font-500"
                    v-html="item.destinationNameHTML"
                  ></div>
                  <div class="text-xs text-[#B2B2B2]" v-html="item.countryNameHTML"></div>
                </div>
              </div>
            </lazy-component>
          </template>
          <div v-else class="pt-40 text-center text-base text-[#B2B2B2] font-500">
            {{ t('emptyTips', [searchInput]) }}
          </div>
        </div>
        <template v-else>
          <van-skeleton :loading="loading">
            <template #template>
              <div class="w-full">
                <van-skeleton-title />
                <div class="my-4 flex">
                  <van-skeleton-image class="mr-4" />
                  <van-skeleton-image class="mr-4" />
                  <van-skeleton-image class="mr-4" />
                </div>
                <van-skeleton-title />
                <div v-for="(_, index) in Array.from({ length: 6 })" :key="index" class="my-4 flex">
                  <van-skeleton-title class="rounded-lg" style="height: 44px" title-width="44px" />
                  <div class="flex-1">
                    <van-skeleton :row="2" />
                  </div>
                </div>
              </div>
            </template>
            <template v-if="cityInfo.recommendDestinationList?.length">
              <div class="px-4 pb-4 text-base text-[#121212] font-500">
                {{ t('featured') }}
              </div>
              <div class="pr-6">
                <div class="flex flex-nowrap overflow-x-auto pb-2 pl-4">
                  <div
                    v-for="item in cityInfo.recommendDestinationList"
                    :key="item.destinationId"
                    class="pr-2.5"
                    @click="onConfirm(item)"
                  >
                    <div border="~ 1 solid #E4E4E4" rounded="xl" p="1.5">
                      <van-image
                        :src="item.img"
                        :width="80"
                        :height="80"
                        :radius="8"
                        class="rounded-xl"
                      />
                      <div class="line-clamp-1 mt-2 text-sm text-black font-500">
                        {{ item.destinationName }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="p-4 text-xs text-[#666666]">
              {{ t('anchor') }}
            </div>
            <van-index-bar
              :index-list="cityListAnchor"
              highlight-color="#07B883"
              class="az-index-bar"
            >
              <template v-for="anchor in cityListAnchor" :key="anchor">
                <van-index-anchor :index="anchor" />
                <lazy-component>
                  <div
                    v-for="item in cityList[anchor]"
                    :key="item.destinationId"
                    class="flex px-4 py-1.25"
                    @click="onConfirm(item)"
                  >
                    <div class="h-11 w-11 flex items-center justify-center rounded-xl bg-[#F6F6F6]">
                      <img class="h-6 w-6" src="~assets/icons/icon_location.png" />
                    </div>
                    <div class="ml-2.5 flex flex-col justify-between py-0.5">
                      <div text="base #121212" font="500">
                        {{ item.destinationName }}
                      </div>
                      <div text="xs #B2B2B2">
                        {{ item.countryName }}
                      </div>
                    </div>
                  </div>
                </lazy-component>
              </template>
            </van-index-bar>
          </van-skeleton>
        </template>
      </div>
    </div>
  </van-popup>
</template>

<script setup lang="ts">
import type { AttrDestinationDto, PartnerCityListRes } from '~/server/types/city.type'

const { t } = useI18n()
const commonStore = useCommonStore()
const filterConditionStore = useFilterConditionsStore()

const selectCityName = computed(() => {
  return filterConditionStore.filterConditions.selectedCity?.destinationName
})

const cityInfo = computed<PartnerCityListRes>(
  () =>
    commonStore.commonData.cityInfo || {
      attrDestinationList: [],
      recommendDestinationList: [],
    },
)

const showPopup = ref(false)
const loading = ref(false)
async function getCityList() {
  loading.value = true
  await commonStore.getCityList()
  loading.value = false
}

function onOpenPopup() {
  if (!cityInfo.value?.recommendDestinationList?.length) {
    getCityList()
  }

  showPopup.value = true
}

function onConfirm(item: AttrDestinationDto) {
  showPopup.value = false

  // Update the selected city
  filterConditionStore.filterConditions.selectedCity = item
}

const searchInput = ref<string>('')
const searchCityList = ref<Array<AttrDestinationDto>>([])
const isSearching = ref<boolean>(false)

watch(showPopup, (value) => {
  if (value) {
    searchInput.value = ''
  }
})

// Enhanced search logic with debouncing for performance optimization
watch(
  searchInput,
  debounce((value) => {
    if (!value) {
      searchCityList.value = []
      isSearching.value = false
      return
    }
    if (value.length >= 2) {
      isSearching.value = true
      searchCityList.value = performCitySearch(value)
    }
  }, 300),
)

function performCitySearch(searchTerm: string): AttrDestinationDto[] {
  const query = searchTerm.toLowerCase()

  // Highlight the matched characters in the search result
  const highlightRegex = new RegExp(`(${query.split('').join('|')})`, 'gi')

  return (
    sortSearch(query)?.map((item) => {
      return {
        ...item,
        destinationNameHTML:
          item.priority > 0
            ? item.destinationName?.replace(
              highlightRegex,
              match => `<span class="text-#07B883">${match}</span>`,
            )
            : item.destinationName,
        countryNameHTML:
          item.priority > 0
            ? item.countryName?.replace(
              highlightRegex,
              match => `<span class="text-#07B883">${match}</span>`,
            )
            : item.countryName,
      }
    }) || []
  )
}

function sortSearch(keyword: string) {
  // 定义正则表达式
  const startsWithRegex = new RegExp(`^${keyword}`, 'i') // 匹配以 keyword 开头的字符串
  const containsRegex = new RegExp(`${keyword}`, 'i') // 匹配包含 keyword 的字符串
  const regex = new RegExp(keyword.split('').join('.*?'), 'gi') // 匹配包含 keyword 的字符串(字母分割)

  // 过滤和排序
  return cityInfo.value.attrDestinationList
    ?.map((item) => {
      if (
        startsWithRegex.test(item.destinationName || '')
        || startsWithRegex.test(item.countryName || '')
      ) {
        return { ...item, priority: 1 } // 优先级 1
      }
      else if (
        containsRegex.test(item.destinationName || '')
        || containsRegex.test(item.countryName || '')
      ) {
        return { ...item, priority: 2 } // 优先级 2
      }
      else if (regex.test(item.destinationName || '') || regex.test(item.countryName || '')) {
        return { ...item, priority: 3 } // 优先级 3，跨字母的
      }
      return {
        ...item,
        priority: -1, // 优先级 -1，不匹配
      }
    })
    .filter(item => item.priority > 0)
    .sort((a, b) => a.priority - b.priority)
}

const cityList = computed(() => groupByFirstLetter(cityInfo.value.attrDestinationList || []))
const cityListAnchor = computed(() => Object.keys(cityList.value))

function groupByFirstLetter(cityList: AttrDestinationDto[]) {
  return cityList.reduce(
    (result, item) => {
      const firstLetter = item.destinationName?.charAt(0).toUpperCase() as string
      if (!result[firstLetter]) {
        result[firstLetter] = []
      }
      result[firstLetter].push(item)
      return result
    },
    {} as { [key: string]: AttrDestinationDto[] },
  )
}
</script>

<style lang="scss">
.az-search__popup {
  height: 100%;
  .van-popup__close-icon {
    top: 24px;
  }
}
.az-search {
  .van-search__content {
    background: #ffffff;
    border: 2px solid #f3f3f3;
    padding: 0 12px 0 24px;
  }
  .van-search__field {
    // height: 52px;
    font-size: 16px;
    color: #b2b2b2;
  }
}

.az-index-bar {
  .van-index-bar__index {
    font-size: 14px;
    color: #a9a9a9;
    font-weight: 400;
  }
  .van-index-bar__highlight {
    background-color: #07b883;
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "title": "Search for a destination",
    "placeholder": "Search your destination",
    "anchor": "Sort cities alphabetically from A to Z.",
    "emptyTips": "Sorry, we couldn't find '{0}'",
    "featured": "Popular destinations"
  }
}
</i18n>
